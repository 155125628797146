import loadable from '@loadable/component';

/* eslint react-app/import/no-webpack-loader-syntax: off */
const LOGO = loadable(() => import('-!svg-react-loader!@assets/images/logo-1x.svg'));

export const VISUALS = {
	LOGO,
};

export default {
	LOGO,
};
