// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ProfileAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
	}

	whoami = () => {
		return this.Client.get(ENDPOINTS.PROFILE.WHOAMI).then(
			(response) => response.data
		);
	};

	update_password = (data) => {
		return this.Client.post(ENDPOINTS.PROFILE.UPDATE_PASSWORD, data).then(
			(response) => response.data
		);
	};

	extend_subscription = () => {
		return this.Client.post(ENDPOINTS.PROFILE.EXTEND_SUBSCRIPTION).then(
			(response) => response.data
		);
	};
}

export default ProfileAPI;
