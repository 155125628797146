// Imports => React
import React, { useEffect, useState, useMemo, useRef, memo } from 'react';
import loadable from '@loadable/component';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import clsx from 'clsx';

// Imports => SCSS
import '@styles/index.scss';

// Imports => Config
import config from '@config';

// Imports => Constants
import {
	AUTHENTICATION_ROUTES,
	DASHBOARD_ROUTES,
	DEFAULT_ROUTE,
	ICONS,
	KEYS,
	PERMISSIONS,
	ROLES,
	ROUTES,
	THEMES,
	TITLES,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcSetDocumentTitle } from '@utils';

// Imports => Molecules
const AcToasterHoc = loadable(() =>
	import('@molecules/ac-toaster-hoc/ac-toaster-hoc.web')
);
const AcModal = loadable(() => import('@molecules/ac-modal/ac-modal.web'));
const AcTimeoutNoticeModal = loadable(() =>
	import('@molecules/ac-timeout-notice-modal/ac-timeout-notice-modal.web')
);

// Imports => Components
const AcHeader = loadable(() => import('@components/ac-header/ac-header.web'));
const AcActivityMonitor = loadable(() =>
	import('@components/ac-activity-monitor/ac-activity-monitor.web')
);
const AcFooter = loadable(() => import('@components/ac-footer/ac-footer.web'));

// Imports => Atoms
const AcErrorBoundary = loadable(() =>
	import('@atoms/ac-error-boundary/ac-error-boundary.web')
);
const AcPrivateRoute = loadable(() =>
	import('@atoms/ac-private-route/ac-private-route.web')
);
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcRipple = loadable(() => import('@atoms/ac-ripple/ac-ripple.web'));
const AcAuthBackground = loadable(() =>
	import('@atoms/ac-auth-background/ac-auth-background.web')
);

const _CLASSES = {
	ROOT: 'ac-root',
	MAIN: 'ac-app',
	UNAUTHORIZED: 'ac-app--unauthorized',
	ROUTE: {
		SECTION: 'ac-route__section',
		AUTHORIZED: 'ac-route__section--authorized',
		HIDDEN: 'ac-route__section--hidden',
	},
};

const App = ({ store }) => {
	const $monitor = useRef(null);
	const $nodeRef = useRef(null);

	const location = useLocation();
	const navigate = useNavigate();

	const [showFreshContentNotice, setShowFreshContentNotice] = useState(false);

	const {
		auth: { is_authorized },
		conversations,
		profile,
		ui,
		freshContentIsAvailable,
	} = store;

	useEffect(() => {
		setTimeout(() => {
			recalculateSections();
		}, 300);

		window.addEventListener('resize', recalculateSections, { passive: true });

		return () =>
			window.removeEventListener('resize', recalculateSections, { passive: true });
	}, []);

	useEffect(() => {
		handleRouteChanged();
		recalculateSections();
		addEvents();

		return () => removeEvents();
	}, [location]);

	useEffect(() => {
		if (freshContentIsAvailable) setShowFreshContentNotice(true);
	}, [freshContentIsAvailable]);

	const addEvents = () => {
		if (!is_authorized) return;
		removeEvents().then(() => {
			document.body.addEventListener('keyup', handleKeyUp, { passive: true });
		});
	};

	const removeEvents = () => {
		return new Promise((resolve) => {
			document.body.removeEventListener('keyup', handleKeyUp);
			resolve();
		});
	};

	const handleKeyUp = async (event) => {
		const key = event.key || event.which;

		const $active_element = document.activeElement;
		const $inputs = ['input', 'select', 'button', 'textarea'];

		if (key && key === 'Enter' && event.ctrlKey) {
			const $button = document.querySelector('button[rel="ac-send-reply-button"]');
			if (AcIsSet($button) && $button.click) $button.click();
		} else if (
			!$active_element ||
			$inputs.indexOf($active_element.tagName.toLowerCase()) === -1
		) {
			if (key) {
				switch (key) {
					case '/':
					case 191:
						const $query = document.querySelector('input[name="query"]');
						if (AcIsSet($query) && $query.focus) $query.focus();
						break;

					case 'c':
					case 67:
						if (!ui.current_modal.visible) {
							const $button = document.querySelector(
								'button[rel="ac-create-button"]'
							);
							if (AcIsSet($button) && $button.click) $button.click();
						}
						break;

					default:
				}
			}
		}
	};

	const handleRouteChanged = (event) => {
		if (
			ui.current_modal &&
			ui.current_modal.type &&
			ui.current_modal.type !== 'timeout'
		) {
			ui.reset(KEYS.MODAL);
		}

		const isAuthRoute = AUTHENTICATION_ROUTES.indexOf(location.pathname) > -1;

		if (is_authorized && !isAuthRoute) {
			profile.who_am_i();

			if ($monitor && $monitor.current) $monitor.current.restart();
		} else {
			if ($monitor && $monitor.current) $monitor.current.stop();
		}
	};

	const recalculateSections = () => {
		// window.requestAnimationFrame(() => {
		const $root = document.querySelector(':root');

		if ($root) {
			const $window = window;
			const $header = document.querySelector('#ac-header');
			const $footer = document.querySelector('#ac-footer');
			const $conversations_aside = document.querySelector('#ac-conversations-aside');
			const $conversations_sidebar = document.querySelector(
				'#ac-conversations-sidebar'
			);

			if ($window)
				$root.style.setProperty('--window-size', `${$window.innerHeight / 10}rem`);
			if ($header)
				$root.style.setProperty('--header-size', `${$header.offsetHeight / 10}rem`);
			if ($footer)
				$root.style.setProperty('--footer-size', `${$footer.offsetHeight / 10}rem`);

			if ($conversations_aside && $conversations_sidebar) {
				const rect = {
					aside: $conversations_aside.offsetHeight,
					container:
						$conversations_aside.querySelector('.ac-container').offsetHeight,
					sidebar: $conversations_sidebar.getBoundingClientRect(),
				};

				$root.style.setProperty(
					'--conversations-sidebar-size',
					`${(rect.aside - rect.container) / 10}rem`
				);
			}
		}
		// });
	};

	const displayTimeoutNoticeModal = async (event) => {
		if (event?.preventDefault) event.preventDefault();
		if (event?.stopPropagation) event.stopPropagation();

		await ui.reset(KEYS.MODAL);
		await ui.set(KEYS.MODAL, {
			title: TITLES.SESSION_TIMEOUT,
			type: 'timeout',
			body: (
				<AcTimeoutNoticeModal
					checkActivity={$monitor && $monitor.current && $monitor.current.check}
					callback={async () => {
						if ($monitor && $monitor.current) $monitor.current.restart();
						await ui.reset(KEYS.MODAL);
					}}
					update={() => {
						ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.SESSION_EXPIRED);
						navigate(ROUTES.LOGIN.path, { replace: true });
					}}
				/>
			),
			centered: true,
			closeable: false,
			visible: true,
			actions: [],
			callback: async () => {
				await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			},
		});
	};

	const isNotAnAuthorizationRoute = useMemo(
		() => AUTHENTICATION_ROUTES.indexOf(location.pathname) === -1,
		[location]
	);

	const authorized = useMemo(() => {
		return is_authorized && isNotAnAuthorizationRoute;
	}, [is_authorized, location, isNotAnAuthorizationRoute]);

	useEffect(() => {
		if (authorized) conversations.ping();
	}, [authorized]);

	const userIsResident = useMemo(() => {
		return (
			AcIsSet(profile.current_profile?.rental_object) &&
			AcIsSet(profile.current_profile?.active_subscription)
		);
	}, [profile.current_profile]);

	const userIsMember = useMemo(
		() => !userIsResident,
		[profile.current_profile, userIsResident]
	);

	const getRouteSectionClassNames = useMemo(() => {
		return clsx(_CLASSES.ROUTE.SECTION, is_authorized && _CLASSES.ROUTE.AUTHORIZED);
	}, [is_authorized]);

	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN && !isNotAnAuthorizationRoute && _CLASSES.UNAUTHORIZED
		);
	}, [isNotAnAuthorizationRoute]);

	const renderModal = useMemo(() => {
		const { current_modal } = ui;
		const { body } = current_modal;

		return (
			<AcModal {...current_modal} callback={current_modal.callback}>
				{body}
			</AcModal>
		);
	}, [
		ui.current_modal,
		ui.current_modal.body,
		ui.current_modal.title,
		ui.current_modal.closeable,
		ui.current_modal.visible,
	]);

	const renderToasterHoc = useMemo(() => {
		return (
			<AcToasterHoc queue={store.toasters.queue} callback={store.toasters.remove} />
		);
	}, [store.toasters, store.toasters.queue]);

	const renderDefaultRoute = useMemo(() => {
		return (
			<Route
				key={`default-route-${DEFAULT_ROUTE.id}`}
				path={'*'}
				element={
					<AcPrivateRoute
						name={DEFAULT_ROUTE.name}
						path={DEFAULT_ROUTE.path}
						component={DEFAULT_ROUTE.component}
						forbidden={DEFAULT_ROUTE.forbidden}
						authorized={is_authorized}
					/>
				}
			/>
		);
	}, [is_authorized]);

	const renderRoutes = useMemo(() => {
		const collection = ROUTES;
		let result = [];
		let key;

		for (key in collection) {
			const item = collection[key];
			const { forbidden, allowed } = item;

			if (forbidden && !is_authorized) continue;
			if (item && item.resident && userIsMember) continue;
			else {
				const object = (
					<Route
						key={`route-${item.id}`}
						path={item.path}
						element={
							<AcPrivateRoute
								name={item.name}
								path={item.path}
								component={item.component}
								forbidden={item.forbidden}
								authorized={is_authorized}
							/>
						}
					/>
				);
				result.push(object);
			}
		}

		return result;
	}, [is_authorized, userIsMember]);

	const renderHeader = useMemo(() => {
		return <AcHeader />;
	}, []);

	const renderFooter = useMemo(() => {
		return <AcFooter />;
	}, []);

	const renderFreshContentNotice = useMemo(() => {
		if (!freshContentIsAvailable) return null;
		if (!showFreshContentNotice) return null;

		return (
			<div
				className={'ac-fresh-content-notice'}
				onClick={() => window.location.reload(true)}
			>
				<p>
					Er is een nieuwe versie van de applicatie beschikbaar!
					<br />
					Klik hier om de applicatie te verversen.
				</p>
			</div>
		);
	}, [showFreshContentNotice, freshContentIsAvailable]);

	return (
		<AcErrorBoundary screen={location.pathname}>
			<AcAuthBackground authorized={authorized} />

			<main
				className={getMainClassNames}
				key={authorized}
				role={'main'}
				node={recalculateSections}
			>
				{authorized && renderHeader}

				<section id={KEYS.SCROLLER} className={getRouteSectionClassNames}>
					<ReactCSSTransitionReplace
						transitionName='fade-wait'
						transitionEnterTimeout={300}
						transitionLeaveTimeout={200}
					>
						<div key={location.key}>
							<Routes location={location}>
								{renderRoutes}
								{renderDefaultRoute}
							</Routes>
						</div>
					</ReactCSSTransitionReplace>
				</section>

				{authorized && renderFooter}

				{authorized && renderModal}

				{renderToasterHoc}

				{authorized && (
					<AcActivityMonitor
						ref={$monitor}
						callback={displayTimeoutNoticeModal}
						authorized={authorized}
					/>
				)}

				{showFreshContentNotice && renderFreshContentNotice}
			</main>
		</AcErrorBoundary>
	);
};

export default withStore(observer(App));
