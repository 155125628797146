// Imports => MOBX
import {
	observable,
	computed,
	action,
	makeObservable,
	runInAction,
	toJS,
} from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsUndefined,
	AcIsNull,
	AcIsArray,
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcRemoveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	options: {},
	conversations: [],
	conversation: null,
};

let app = {};
let interval = null;

export class ConversationsStore {
	constructor(store) {
		makeObservable(this);

		AcAutoSave(this);

		app.store = store;
	}

	@observable
	conversations = _default.conversations;

	@observable
	conversation = _default.conversation;

	@computed
	get current_conversations() {
		if (
			!AcIsSet(this.conversations) ||
			!AcIsArray(this.conversations) ||
			this.conversations.length === 0
		)
			return null;

		const sortedByLastMessageDate = this.conversations.slice().sort((a, b) => {
			return new Date(b.last_message_date) - new Date(a.last_message_date);
		});

		return toJS(sortedByLastMessageDate);
	}

	@computed
	get current_number_of_conversations() {
		if (!this.current_conversations) return 0;
		return this.current_conversations.length;
	}

	@computed
	get has_conversations() {
		return this.current_number_of_conversations > 0;
	}

	@computed
	get current_number_of_unread_conversations() {
		if (!this.has_conversations) return 0;
		return this.current_conversations.filter((m) => m.is_unread).length;
	}

	@computed
	get current_recent_conversations() {
		/*
			recent articles: return the (max) 3 most recent news articles
		*/
		if (!this.has_conversations) return null;
		return toJS(this.current_conversations.slice(0, 3));
	}

	@computed
	get current_conversation() {
		return toJS(this.conversation);
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@computed
	get is_loading() {
		return toJS(this.loading.status);
	}

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Checking your conversation',
		};
	}

	@observable
	busy = {
		status: false,
		message: null,
	};

	@computed
	get is_busy() {
		return toJS(this.busy.status);
	}

	@action
	setBusy(state, message) {
		this.busy = {
			status: state || false,
			message: message || 'Decyphering your conversation',
		};
	}

	@observable
	replying = {
		status: false,
		message: null,
	};

	@computed
	get is_replying() {
		return toJS(this.replying.status);
	}

	@action
	setReplying(state, message) {
		this.replying = {
			status: state || false,
			message: message || 'Decyphering your conversation',
		};
	}

	@action
	refreshThisProvidedConversation = (id, conversation) => {
		if (!AcIsSet(this.current_conversations)) return;

		const collection = this.current_conversations;
		const len = collection.length;
		let n = 0;
		let result = collection;

		for (n; n < len; n++) {
			const item = collection[n];

			if (item.id === id) {
				result[n] = conversation;
				break;
			}
		}

		this.set(KEYS.CONVERSATIONS, result);
	};

	@action
	ping = () => {
		return new Promise((resolve) => {
			if (interval) clearInterval(interval);

			// Initial request
			this.index(false);

			// Request every n seconds (1000ms), every 30 seconds in this case
			interval = setInterval(() => this.index(false), 30 * 1000);

			resolve();
		});
	};

	@action
	index = (withLoading = true) => {
		this.setLoading(withLoading);

		return app.store.api.conversations
			.index()
			.then((response) => {
				const { data } = response;
				this.set(KEYS.CONVERSATIONS, data);

				this.setLoading(false);
				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van alle berichten`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	show = (id, setbusy = true, refresh = false) => {
		if (setbusy) this.setBusy(true);

		return app.store.api.conversations
			.show(id)
			.then((response) => {
				const { data } = response;
				this.set(KEYS.CONVERSATION, data);

				// If we want to refresh the fetch conversations in the conversation-sidebar, pass refresh = true here
				if (refresh) this.refreshThisProvidedConversation(id, data);

				if (setbusy) this.setBusy(false);
				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het ophalen van bericht met ID ${id}`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				if (setbusy) this.setBusy(false);
				throw error;
			});
	};

	@action
	mark_as_read = (id) => {
		this.setBusy(true);

		return app.store.api.conversations
			.mark_as_read(id)
			.then(async (response) => {
				const { data } = response;

				const collection = this.current_conversations.slice();
				const len = collection.length;
				let n = 0;
				let result = collection;

				for (n; n < len; n++) {
					const item = collection[n];

					if (item.id !== id) continue;
					else if (item.id === id) {
						result[n] = data;
						break;
					}
				}

				this.set(KEYS.CONVERSATIONS, result);
				this.set(KEYS.CONVERSATION, data);

				await this.show(id);

				this.setBusy(false);
				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het updaten van bericht met ID ${id}`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setBusy(false);
				throw error;
			});
	};

	@action
	store(subject, message, files = null) {
		const attachments = AcIsSet(files) && files.length > 0;
		let data = {};

		this.setReplying(true);

		if (attachments) {
			const formData = new FormData();
			formData.append('subject', subject);
			formData.append('message', message);

			const len = files.length;
			let n = 0;

			for (n; n < len; n++) {
				const file = files[n];
				formData.append('files[]', file, file.name);
			}

			data = formData;
		} else {
			data.subject = subject;
			data.message = message;
		}

		return app.store.api.conversations
			.store(data, attachments)
			.then(async (response) => {
				await this.ping();
				await this.show(response?.data?.id);

				app.store.toasters.add({
					variant: 'success',
					delay: 5000,
					title: 'Het bericht is verstuurd!',
				});

				this.setReplying(false);

				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het versturen van het bericht`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setReplying(false);
				throw error;
			});
	}

	@action
	reply(id, message, files = []) {
		const attachments = AcIsSet(files) && files.length > 0;
		let data = {};

		this.setReplying(true);

		if (attachments) {
			const formData = new FormData();
			formData.append('content', message);

			const len = files.length;
			let n = 0;

			for (n; n < len; n++) {
				const file = files[n];
				formData.append('files[]', file, file.name);
			}

			data = formData;
		} else {
			data.content = message;
		}

		return app.store.api.conversations
			.reply(id, data, attachments)
			.then(async (response) => {
				// Last param is true, since we want to refresh the fetched conversation in the conversation-sidebar
				await this.show(id, false, true);

				app.store.toasters.add({
					variant: 'success',
					delay: 5000,
					title: null,
					description:
						'Het bericht is verstuurd naar het bestuur van Elck Wat Wils. Het bestuur reageert doorgaans binnen 3 weken.',
				});

				this.setReplying(false);
				return response;
			})
			.catch((error) => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: `Er is iets fout gegaan tijdens het versturen van het bericht`,
					description: `Probeer het opnieuw of neem contact op met <em>${KEYS.SUPPORT_EMAIL_ADDRESS}</em>`,
					code: AcFormatErrorCode(error),
				});

				this.setReplying(false);
				throw error;
			});
	}

	@action
	set = (target, value, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save = false) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise((resolve) => {
			runInAction(() => {
				this[target] = _default[target];
			});

			if (save && AcIsNull(_default[target])) {
				AcRemoveState(target);
			} else if (save) {
				AcSaveState(target, _default[target]);
			}

			resolve();
		});
	};
}

export default ConversationsStore;
