// Imports => Constants
import { ENDPOINTS } from '@constants';

export class NewsAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
	}

	index = () => {
		return this.Client.get(ENDPOINTS.NEWS.INDEX).then((response) => response.data);
	};

	show = (id) => {
		return this.Client.get(ENDPOINTS.NEWS.SHOW(id)).then(
			(response) => response.data
		);
	};
}

export default NewsAPI;
