// Imports => Constants
import { ENDPOINTS } from '@constants';

export class FilesAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
		this.DownloadClient = Instance.DownloadClient;
	}

	index = () => {
		return this.Client.get(ENDPOINTS.FILES.INDEX).then((response) => response.data);
	};

	download = (id, type) => {
		// if (type === 'document') {
		// 	return this.DownloadClient.get(ENDPOINTS.DOCUMENTS.DOWNLOAD(id)).then(
		// 		(response) => response
		// 	);
		// }

		return this.DownloadClient.get(ENDPOINTS.FILES.DOWNLOAD(id)).then(
			(response) => response
		);
	};
}

export default FilesAPI;
