import { AcLockObject } from '@utils/ac-lock-object';

export const TITLES = AcLockObject({
	ACTIVATE: 'Activeren',
	BASE: 'Mijn EWW',
	CONVERSATIONS: 'Meldingen',
	DOCUMENTS: 'Documenten',
	FAQ: 'Veelgestelde vragen',
	FORGOT_PASSWORD: 'Wachtwoord vergeten',
	HOME: 'Overzicht',
	LOGIN: 'Inloggen',
	MESSAGES: 'Berichten',
	NEWS: 'Nieuws',
	NEW_CONVERSATION: 'Nieuwe melding',
	PROFILE: 'Profiel',
	RESET_PASSWORD: 'Wachtwoord herstellen',
	SESSION_EXPIRED: 'De actieve sessie is beeindigd',
	SESSION_TIMEOUT: 'De actieve sessie eindigt binnenkort',
	TERMS_CONDITIONS: 'Algemene voorwaarden',
	UPDATE_PASSWORD: 'Wijzig je wachtwoord',
});
