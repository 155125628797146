// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ConversationsAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
		this.UploadClient = Instance.UploadClient;
	}

	index = () => {
		return this.Client.get(ENDPOINTS.CONVERSATIONS.INDEX).then(
			(response) => response.data
		);
	};

	show = (id) => {
		return this.Client.get(ENDPOINTS.CONVERSATIONS.SHOW(id)).then(
			(response) => response.data
		);
	};

	store = (data, attachments = false) => {
		if (attachments) {
			return this.UploadClient.post(ENDPOINTS.CONVERSATIONS.STORE, data).then(
				(response) => response.data
			);
		}

		return this.Client.post(ENDPOINTS.CONVERSATIONS.STORE, data).then(
			(response) => response.data
		);
	};

	mark_as_read = (id) => {
		return this.Client.post(ENDPOINTS.CONVERSATIONS.MARK_AS_READ(id)).then(
			(response) => response.data
		);
	};

	reply = (id, data, attachments = false) => {
		if (attachments) {
			return this.UploadClient.post(ENDPOINTS.CONVERSATIONS.REPLY(id), data).then(
				(response) => response.data
			);
		}

		return this.Client.post(ENDPOINTS.CONVERSATIONS.REPLY(id), data).then(
			(response) => response.data
		);
	};
}

export default ConversationsAPI;
